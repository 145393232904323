
/* module.exports.BASE_URL = "https://api.flatpacksaustralia.com.au" //live
module.exports.UPLOAD_PATH = '/home/flatpac1/public_html' //live
module.exports.UPLOAD_URL = 'https://flatpacksaustralia.com.au/' //live 

module.exports.BASE_URL = "https://test.flatpackswa.com.au" //staging
module.exports.UPLOAD_PATH = '/var/www/vhosts/flatpackswa.com.au/staging.flatpackswa.com.au' //staging
module.exports.UPLOAD_URL = 'https://staging.flatpackswa.com.au/' //staging
*/

module.exports.BASE_URL = 'http://flatpacks.server';
module.exports.UPLOAD_URL = 'http://localhost:3000/';
module.exports.UPLOAD_PATH = '/Volumes/M225GB/projects/flatpacks/frontend/src/flatpack/public';

module.exports.VERSION = ''
module.exports.GOOGLE_API_KEY = 'AIzaSyBXGSvA_Bsm39UzEn-SFdx0J6ugs7KmuQA'; //'AIzaSyDmz2NwD6J4Kxk0VnWVcqla3yV58bWCp0g'
module.exports.STRIPE_KEY = 'pk_test_V8NDZ2715UV7xdB8JYovP0Qe00bblxzQrQ';

module.exports.ABN_GUID = 'b8fffe22-b880-4338-a80a-d0a042ecaa6a';
module.exports.PHONE = '1800 497 143';
module.exports.EMAIL = 'sales@flatpacksaustralia.com.au'
module.exports.EMAIL = 'sales@flatpacksaustralia.com.au';

module.exports.TOP_LOGO = '/images/logo-2-australia.png';
module.exports.FOOTER_LOGO = '/images/logo-2-australia-white.png'
